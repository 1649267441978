import HMIBgc from '@/assets/product/HMIBgc.png'
import OBUBgc from '@/assets/product/OBUBgc.png'
import RSCUBgc from '@/assets/product/RSCUBgc.png'
import RSUBgc from '@/assets/product/RSUBgc.png'
import managementBgc from '@/assets/product/managementBgc.png'
import LSYTJBgc from '@/assets/product/LSYTJBgc.png'
import moveDeviceBgc from '@/assets/product/moveDeviceBgc.png'
import trainBusBgc from '@/assets/product/trainBusBgc.png'
import byWireChassisBgc from '@/assets/product/byWireChassisBgc.png'
import VCUBgc from '@/assets/product/VCUBgc.png'


export interface SolutionItemI {
  id: number,
  title: string;
  scenario: string;
  description: string;
}

// 解决方案
const solutionList: SolutionItemI[] = []
/*for (let i = 0; i < 6; i++) {
  solutionList.push({
    id: 1,
    title: '高速公路车路协同解决方案' + i,
    scenario: '绕城高速、省际高速',
    description: '福马智行无人驾驶观光车主要由无人驾驶系统和线控底盘系统两大核心部分组成。线控底盘系统作为整车的下层执行部件响应上层无人驾驶系统的控制指令'
  })
}*/
solutionList.push({
  id: 1,
  title: '高速公路车路协同解决方案',
  scenario: '绕城高速、省际高速',
  description: '福马智行无人驾驶观光车主要由无人驾驶系统和线控底盘系统两大核心部分组成。线控底盘系统作为整车的下层执行部件响应上层无人驾驶系统的控制指令'
})

export interface ProductItemI {
  id: number;
  name: string;
  desc: string;
  imgSrc: string;

  [propName: string]: any
}

// 产品介绍
const productNum = 5
const productList: ProductItemI[] = [
  // rscu
  {
    id: 0,
    name: '边缘计算单元RSCU',
    desc: '车路协同路侧系统的核心设备，接入感知设备，连接RSU，对感知数据和车辆数据进行分析和处理',
    imgSrc: RSCUBgc,
    to: 'ProductRSCU'
  },
  // RSU
  {
    id: 1,
    name: '路侧单元RSU',
    desc: '路侧单元RSU是基于C-V2X技术开发的路侧通信设备，该产品支持V2X PC5和Uu双模式通信，符合3GPP R14协议规范，能够实现与 OBU 之间直接通信。\n ',
    imgSrc: RSUBgc,
    to: 'ProductRSU'
  },
  // OBU
  {
    id: 2,
    name: '车载单元OBU',
    desc: '车载单元OBU是基于C-V2X技术开发的车载通信设备，该产品支持V2X PC5和Uu双模式通信，符合3GPP R14协议规范，能够实现 与 RSU 之间直接通信。',
    imgSrc: OBUBgc,
    to: 'ProductOBU'
  },
  // 云控平台
  {
    id: 3,
    name: '车路协同云控平台',
    desc: '系统包括后台管理、车辆监控功能，起到打通各平台能力、贯通业务线上化流程的作用；平台向乘客提供车辆位置、实时运行信息。展示大屏将无人车辆及相关业务可视化，通过图形、数据图表等形式实时展示车辆位置、运行状态等信息。\n',
    imgSrc: managementBgc,
    to: 'ProductCouldCPlatform'
  },
  // HMI
  {
    id: 4,
    name: '车载HMI软件',
    desc: '配合车载单元OBU实现OBU收发数据可视化展示',
    imgSrc: HMIBgc,
    to: 'ProductHMI'
  },
  // 雷视一体机
  {
    id: 5,
    name: '雷视一体机',
    desc: '车载单元OBU是基于C-V2X技术开发的车载通信设备，该产品支持V2X PC5和Uu双模式通信，符合3GPP R14协议规范，能够实现 与 RSU 之间直接通信。',
    imgSrc: LSYTJBgc,
    to: 'ProductLSYTJ'
  },
  // 无人驾驶观光车
  {
    id: 6,
    name: '移动式车路协同',
    desc: '系统包括后台管理、车辆监控功能，起到打通各平台能力、贯通业务线上化流程的作用；平台向乘客提供车辆位置、实时运行信息。展示大屏将无人车辆及相关业务可视化，通过图形、数据图表等形式实时展示车辆位置、运行状态等信息。\n',
    imgSrc: moveDeviceBgc,
    to: 'MoveDevice'
  },
  // 无人驾驶实训车
  {
    id: 7,
    name: '无人驾驶实训车',
    desc: '配合车载单元OBU实现OBU收发数据可视化展示',
    imgSrc: trainBusBgc,
    to: 'ProductTrainBus'
  },
  // 线控底盘
  {
    id: 8,
    name: '线控底盘',
    desc: '配合车载单元OBU实现OBU收发数据可视化展示',
    imgSrc: byWireChassisBgc,
    to: 'ProductByWireChassis'
  },
  // 整车控制器VCU
  {
    id: 9,
    name: ' 整车控制器VCU',
    desc: '配合车载单元OBU实现OBU收发数据可视化展示',
    imgSrc: VCUBgc,
    to: 'ProductVCU'
  },
]


// 动态列表
export interface MomentItemI {
  id: number;
  date: string; // 年月
  day: string; // 日
  link: string;
  title: string;
}

const momentList: MomentItemI[] = [
  {
    id: 20250314,
    date: '2025/03',
    day: '14',
    link: 'https://mp.weixin.qq.com/s/5jFVIlPs8vUIGq0rWOj2YQ',
    title: '福马智行获评成都高新区“瞪羚企业”'
  },
  {
    id: 20241127,
    date: '2024/11',
    day: '27',
    link: 'https://mp.weixin.qq.com/s/TiKvqhNn5SbDfVWa-FT_AA',
    title: '福马智行荣登“2024‘成都造’智能网联汽车产品30强”榜单'
  },
  {
    id: 20231207,
    date: '2023/12',
    day: '07',
    link: 'https://mp.weixin.qq.com/s/Zeez_5hqUJ2BYRKzEuKizw',
    title: '福马智行获“成都市新经济种子企业”认定'
  },
  {
    id: 20230323,
    date: '2023/03',
    day: '23',
    link: 'https://mp.weixin.qq.com/s/DxZGqSNk0ZPOsgqRsFylZQ',
    title: '福马智行获“四川省创新型中小企业”认定'
  },
  {
    id: 20230105,
    date: '2023/01',
    day: '05',
    link: 'https://mp.weixin.qq.com/s/Jnp23_N__hoInoUeKXOlyQ',
    title: '福马智行获“国家高新技术企业”认定'
  }, {
    id: 20211230,
    date: '2021/12',
    day: '30',
    link: 'https://mp.weixin.qq.com/s/BeUY5hMtqEKhrN5QZu7g8Q',
    title: '福马智行应邀参加2021成都新经济“双千”发布会项目路演'
  }, {
    id: 20211112,
    date: '2021/11',
    day: '12',
    link: 'https://mp.weixin.qq.com/s/j7pbgK5htWLbtNlpXz1DEA',
    title: '福马智行自研车路协同云控平台成功中标'
  }, {
    id: 20211012,
    date: '2021/10',
    day: '12',
    link: 'https://mp.weixin.qq.com/s/Xvk4cu3Xe5bqn5OJVaOcog',
    title: '福马智行获“科技型中小企业”、“种子期雏鹰企业'
  }, {
    id: 20210916,
    date: '2021/09',
    day: '16',
    link: 'https://mp.weixin.qq.com/s/o-GwPDDQ0DucAhaJM5-LSw',
    title: '福马智行参与起草制定的智能网联汽车四项团体标准发布实施'
  }, {
    id: 20210812,
    date: '2021/08',
    day: '12',
    link: 'https://mp.weixin.qq.com/s/ZMMaQX8txSQ_EZ6ptwhQqQ',
    title: '成都市智慧城市基础设施和智能网联汽车示范项目建设情况调研组参观考察福马智行无人驾驶环卫车'
  }]
export {
  solutionList,
  productList,
  momentList
}
